import { PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';

import { API, parse_API_messages } from "./api";
import { is_mobile_app, Log_Messages } from "./core";
import { print_global_message } from './ui/global_message';
import { _t } from './localization';
import { Scene_Manager } from './scenes';
import { Auth } from './auth';
import { Updater } from './updater';

export class Push_Notification
{
    title: string;
    body: string;
    tag: string;

    constructor(handle: PushNotificationSchema)
    {
        this.title = handle.title || '';
        this.body = handle.body || '';
        this.tag = handle.tag || handle.data.tag || ''; // Android uses a tag. For consistency pass the tag with iOS data as well 

        this._handle = handle;
    }

    _handle: PushNotificationSchema;
}

type Push_Notification_Callback = (notifications: Push_Notification[]) => void;

export class Push_Notifications
{
    static async get_all(): Promise<Push_Notification[]>
    {
        if( !is_mobile_app() )
            return [];

        const notifications = await PushNotifications.getDeliveredNotifications();
        return notifications.notifications.map(notification => new Push_Notification(notification));
    }

    static async init(updater: Updater)
    {
        if( !is_mobile_app() )
            return [];

        this._updater = updater;

        try{
			let status = await PushNotifications.checkPermissions();
			if( status.receive === 'prompt' || status.receive === 'prompt-with-rationale' )
				status = await PushNotifications.requestPermissions();
			
			if( status.receive !== 'granted' ){
				print_global_message('error', _t('push/not_granted'));
				return;
			}

			await PushNotifications.addListener('registration', this._on_register_request.bind(this));
			await PushNotifications.addListener('registrationError', () => {print_global_message('error', _t('push/error')) });
			await PushNotifications.addListener('pushNotificationReceived', this._on_received.bind(this)); // For foreground notifications.
			await PushNotifications.addListener('pushNotificationActionPerformed', this._on_action_performed.bind(this)); // For background notifications.
		
			await PushNotifications.register();

		}catch(e){
			console.log('Push notifications not supported.');
		}
    }

    static async register(push_token: string, log: Log_Messages = {}): Promise<boolean>
    {
        if( !is_mobile_app() )
            return false;

        const response = await API.POST('/auth/register_push_notifications', { token: push_token });
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return false;

        localStorage.setItem('push_notification_token', push_token);

        return true;
    }

    static register_callback(callback: Push_Notification_Callback)
    {
        this._callbacks.push(callback);
    }

    static async remove(notifications: Push_Notification[])
    {
        if( !is_mobile_app() )
            return [];
        
        const handles = notifications.map(notification => notification._handle);
        await PushNotifications.removeDeliveredNotifications({
            notifications: handles
        });
    }

    static unregister()
    {
        if( !is_mobile_app() )
            return;

        return PushNotifications.unregister();
    }

    static _callbacks: Push_Notification_Callback[] = [];
    static _updater: Updater;

    static async _on_action_performed(input: any)
    {
        if( !input.notification.data || !input.notification.data.url )
            return;

        await Scene_Manager.open_url(input.notification.data.url);
        this._updater.run();
    }

    static async _on_received(schema: PushNotificationSchema)
    {
        PushNotifications.removeDeliveredNotifications({notifications: [schema]});

        // Android crashes when a callback removes the schema directly and for iOS it doesn't matter. So ask for them again.
        //const notifications = await this.get_all();
        //this._callbacks.forEach(callback => callback(notifications));
    }

    static _on_register_request(token: Token)
    {
        if( Auth.current_user )
            Push_Notifications.register(token.value);
    }
}
