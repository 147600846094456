import { Capacitor } from '@capacitor/core';

//
// Cookies.
//
export function set_cookie(key: string): void;
export function set_cookie(key: string, value: string): void;
export function set_cookie(key: string, value?: string): void
{
    if( value === undefined ){
        const key_value = key.split('=');
        if( key_value.length !== 2 )
            return;

        key = key_value[0];
        value = key_value[1];
    }

    const pairs = document.cookie.split(';');
    let result = '';
    let exists = false;
    for(let i = 0; i < pairs.length; i++){
        const key_value = pairs[i].split('=');
        if( key_value.length !== 2 )
            continue;

        if( key_value[0] === key ){
            result += key_value[0] + '=' + value + ';';
            exists = true;
        }else{
            result += key_value[0] + '=' + key_value[1] + ';';
        }
    }

    if( !exists )
        result += key + '=' + value + ';';

    document.cookie = result;
}

//
// Crypto.
//
export function generate_hash(length: number): string
{
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
 
    let result = '';
    for(let i = 0; i < length; i++)
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    
    return result;
}

//
// Date.
//
export function date_to_local(input: string)
{
    return new Date(input + ' UTC').toLocaleString()
}

export function date_to_UTC(input: string)
{
    const date = new Date(input)

    const month = date.getUTCMonth() + 1

    let minutes = date.getUTCMinutes().toString()
    if( minutes.length < 2 )
        minutes = '0' + minutes

    let seconds = date.getUTCSeconds().toString()
    if( seconds.length < 2 )
        seconds = '0' + seconds

    return date.getUTCFullYear() + '-' + month + '-' + date.getUTCDate()
        + ' ' + date.getUTCHours().toString() + ':' + minutes + ':' + seconds
}

export function print_pretty_date(date: Date)
{
    const year_setting = date.getFullYear() < UTC().getFullYear() ? 'numeric' : undefined;

    return date.toLocaleString(undefined, {
        weekday: 'short',
        month: 'long',
        year: year_setting,
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        formatMatcher: 'best fit',
    });
}

export function serialize_date(input: Date): string
{
    const year_text = String(input.getUTCFullYear());
    const month = input.getUTCMonth() + 1;
    const month_text = month < 10 ? "0" + month : month;
    const day = input.getUTCDate();
    const day_text = day < 10 ? "0" + day : day;

    return `${year_text}-${month_text}-${day_text}`;
}

export function serialize_datetime(input: Date): string
{
    const hours = input.getUTCHours();
    const hours_text = hours < 10 ? "0" + hours : hours;
    const minutes = input.getUTCMinutes();
    const minutes_text = minutes < 10 ? "0" + minutes : minutes;
    const seconds = input.getUTCSeconds();
    const seconds_text = seconds < 10 ? "0" + seconds : seconds;
        
    return `${serialize_date(input)} ${hours_text}:${minutes_text}:${seconds_text}`;
}

export function UTC(): Date;
export function UTC(input: unknown): Date | Date;
export function UTC(input?: unknown): Date | undefined
{
    let date: Date;
    if( typeof input === 'string' ){
        if( !Number.isNaN(Number(input)) )
            date = new Date(Number(input) * 1000);
        else if( /^\d{4}-\d{1,2}-\d{1,2}$/.test(input) )
            date = new Date(input + ' 00:00:00Z');
        else if( /^\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{2}(:\d{2})?$/.test(input) )
            date = new Date(input + 'Z');
        else
            return;
    }else if( typeof input === 'number' ){
        date = new Date(input);
    }else if( input instanceof Date ){
        date = input;
    }else if( input === undefined ){
        date = new Date();
    }else{
        return;
    }
    
    return isNaN(date.getTime()) ? undefined : date;
}

//
// Device.
//
export function is_active(): boolean
{
    return document.visibilityState === 'visible';

    // Maybe necessary for iOS.
    // let { isActive } = await App.getState();
    // return isActive;
}

export function is_android(): boolean
{
    // Web or app.
    return is_android_app() || is_android_web();
}

export function is_android_app(): boolean
{
    return Capacitor.getPlatform() === 'android';
}

export function is_android_web(): boolean
{
    return navigator.userAgent.toLowerCase().includes('android') && !is_android_app(); // Check the app just to be sure.
}

export function is_ios(): boolean
{
    // Web or app.
    return is_ios_app() || is_ios_web();
}

export function is_ios_app(): boolean
{
    return Capacitor.getPlatform() === 'ios';
}

export function is_ios_web(): boolean
{
    return navigator.userAgent.toLowerCase().includes('iphone') && !is_ios_app(); // Check the app just to be sure.
}

export function is_local(): boolean
{
    return location.port === '8080';
}

export function is_mobile(): boolean
{
    return is_android() || is_ios();
}

export function is_mobile_app(): boolean
{
    return is_android_app() || is_ios_app();
}

export function is_mobile_web(): boolean
{
    return is_android_web() || is_ios_web();
}

//
// Log Messages.
//
export type Log_Message_Type = 'error' | 'success' | 'warning' | 'info';

export function is_log_message_type(value: string): value is Log_Message_Type
{
    return value === 'error' || value === 'success' || value === 'warning' || value === 'info';
}

export type Log_Message = {type: Log_Message_Type, code: string, message: string};
export type Log_Messages = Record<string, Log_Message>;

//
// Text
//
export function sprintf(text: string, ...args: Array<string | number>): string
{
    let i = 0;
    return text.replace(/%s/g, () => args[i++].toString());
}

export function to_title_case(text: string): string
{
    var parts = text.toLowerCase().split(' ');
    for(var i = 0; i < parts.length; i++)
        parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].substring(1);
    return parts.join(' '); 
}

//
// Types.
//
export function is_array(x: any): x is Array<any>
{
    return Array.isArray(x)
}

export function is_object(x: any): x is Object
{
    return x && typeof(x) && !Array.isArray(x)
}

export function parse_JSON(text: string): any | undefined
{
    try{ return JSON.parse(text) }catch{}
}

//
// Miscellanious.
//
export function serialize_query(data: {[key: string]: string}): string
{
    let result = ''
    for(const key in data)
        result += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]) + '&'
    return result
}

export function sleep(ms: number): Promise<void>
{
    return new Promise((resolve) => setTimeout(resolve, ms))
}
