import { Activity } from "../../activities";
import { Auth } from "../../auth";
import { Message, Chat_Manager } from "../../chats";
import { _t } from "../../localization";
import { Reporting } from "../../reporting";
import { User } from "../../users";
import * as DOM from "../DOM";
import { print_global_messages } from "../global_message";
import { Popup } from "./popup";

type Item_Type = Activity | Message | User;
const OTHER_OPTION_KEY = 'other';

export class Report_Popup extends Popup
{
    constructor(item: Item_Type)
    {
        super('message_options', { title: _t('general/report') });

        this._item = item;

        const summary_keys = ['identity', 'sexual', 'violence', 'hateful', 'dangerous', 'spam', 'children'];
        if( item instanceof User )
            summary_keys.unshift('cancel', 'no_show');

        const summaries = summary_keys.map(key => { return { key, text: _t('reporting/summary/' + key)}});
        summaries.push({key: OTHER_OPTION_KEY, text: _t('general/other')});

        this._form = document.createElement('form');
        this._form.addEventListener('submit', this._on_submit.bind(this));
        this._body.append(this._form);

        summaries.forEach(row => {
            const row_el = document.createElement('label');
            row_el.style.display = 'block';
            row_el.style.marginBottom = '0';
            row_el.textContent = row.text;
            this._form.append(row_el);

            const input_el = document.createElement('input');
            input_el.name = 'summary';
            input_el.type = 'radio';
            input_el.value = row.key;
            row_el.prepend(input_el);
        });

        const hr = document.createElement('hr');
        this._form.append(hr);

        const description_label = document.createElement('label');
        description_label.className = 'full-width';
        description_label.innerHTML = _t('general/description');
        this._form.append(description_label);

        const description_el = document.createElement('textarea');
        description_el.className = 'full-width';
        description_el.name = 'description';
        description_label.append(description_el);

        const submit_button = document.createElement('button');
        submit_button.className = 'primary full-width';
        submit_button.innerHTML = _t('general/confirm');
        submit_button.addEventListener('click', this._on_submit.bind(this));
        this._form.append(submit_button);
    }

    private _form: HTMLFormElement;
    private _item: Item_Type;

    private async _on_submit(ev: Event)
    {
        ev.preventDefault();

        const data = DOM.parse_form(this._form);
        if( !data['summary'] || typeof data['summary'] !== 'string' ){
            alert(_t('reporting/error/no_reason'));
            return;
        }

        if( data['summary'] === OTHER_OPTION_KEY && !(data['description'] && typeof data['description'] === 'string') ){
            alert(_t('reporting/error/no_descripiton'));
            return;
        }

        if( typeof data['description'] !== 'string' ){
            alert(_t('reporting/error/no_descripiton'));
            return;
        }

        const log = {};
        Reporting.report(this._item, data['summary'], data['description'] || 'none', log).then(() => {
            print_global_messages(log);
        });

        this.close();
    }
}