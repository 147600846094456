import { Activity } from "../../activities";
import { _t } from "../../localization";
import { Popup } from "./popup";
import { Report_Popup } from "./report_popup";

export class Activity_Options_Popup extends Popup
{
    constructor(activity: Activity)
    {
        super('activity_options', { title: _t('general/options') });

        this._activity = activity;

        const report_button = document.createElement('button');
        report_button.className = 'menu-item danger';
        report_button.innerHTML = _t('general/report');
        report_button.addEventListener('click', this._on_report.bind(this));
        this._body.append(report_button);
    }

    private _activity: Activity;

    private async _on_report()
    {
        new Report_Popup(this._activity).open();
        this.close();
    }
}