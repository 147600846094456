import { Activity } from "./activities";
import { API, API_Data, parse_API_messages } from "./api";
import { Message } from "./chats";
import * as core from "./core";
import { User } from "./users";

export class Report
{
    id = 0;
    reported_user = 0;
    reporting_user = 0;
    item? = 0;
    item_type? = 0;
    summary = '';
    description = '';
    status = '';
    created_at?: Date;
}

export class Reporting
{
    static async report(
        item: Activity | Message | User,
        summary: string,
        description: string,
        log: core.Log_Messages = {}
    ): Promise<boolean> {

        const data: any = {};

        data['summary'] = summary;
        data['description'] = description;

        if( item instanceof Activity )
            data['activity'] = item.uid;
        else if( item instanceof Message )
            data['message'] = item.id;
        else if( item instanceof User )
            data['user'] = item.uid

        const response = await API.POST('/report', data);
        if( !response )
            return false;
        
        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return false;

        return true;
    }
}
