import { API, API_Data, parse_API_messages } from "./api";
import { Log_Messages } from "./core";
import { Locale } from "./localization";
import { Push_Notifications } from './push_notifications';
import { Updater } from "./updater";
import { User, Users } from "./users";

export const CSRF_TOKEN_KEY = 'csrf_token';

export class Auth
{
    static current_user?: User;

    static init(updater: Updater)
    {
        this._updater = updater;
        this._init_tabs();
    }

    static async login(data: API_Data, log: Log_Messages = {}): Promise<boolean>
    {
        const response = await API.POST('/auth/login', data);
        if( !response ){
            return false;
        }else if( response.status !== 200 ){
            parse_API_messages(log, await response.json());
            return false;
        }

        const result =  await response.json();
        this._process_login_data(result);

        return true;
    }

    static async login_with_oauth_token(token: string, cookie: string, log: Log_Messages = {}): Promise<boolean>
    {
        const response = await API.POST('/auth/oauth_login', {'token': token, 'cookie': cookie});
        if( !response ){
            return false;
        }else if( response.status !== 200 ){
            parse_API_messages(log, await response.json());
            return false;
        }

        const result =  await response.json();
        this._process_login_data(result);

        return true;
    }

    static async logout(log: Log_Messages = {}): Promise<boolean>
    {
        const response = await API.POST('/auth/logout');
        if( !response ){
            return false;
        }else if( response.status !== 200 && response.status !== 401 ){
            parse_API_messages(log, await response.json());
            return false;
        }

        this._remove_session();
		location.pathname = '/';

        return true;
    }

    static redirect_to_requested_url()
    {
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get('redirect');
        if( !redirect ){
            location.pathname = '/';
            return;
        }

        const url = new URL(redirect);

        // We use the origin of the current location to prevent redirecting to a different domain.
        open(location.origin + url.pathname + url.search, '_self');
    }

    static async request_email_verification(log: Log_Messages = {}): Promise<User | undefined>
    {
        if( !this.current_user )
            return;

        const response = await API.POST('/user/' + encodeURIComponent(this.current_user.uid) + '/email');
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;
 
        return Users.parse(result.data, this.current_user);
    }

    static async resume_session(log: Log_Messages = {}): Promise<User | undefined>
    {
        this.current_user = undefined;

        API.callbacks.push(this._logout_unauthenticated_user.bind(this));

        if( !localStorage.getItem(CSRF_TOKEN_KEY) )
            return;

        this.current_user = await Users.get_current(log);
        if( !this.current_user )
            return;

        Push_Notifications.init(this._updater);

        return this.current_user;
    }

    static async verify_email(hash: string, log: Log_Messages = {}): Promise<User | undefined>
    {
        if( !this.current_user )
            return;

        const response = await API.PUT('/user/' + encodeURIComponent(this.current_user.uid) + '/email/verify', { hash: hash });
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;
        
        return Users.parse(result.data, this.current_user);
    }

    private static _auth_broadcast = new BroadcastChannel('login');
    private static _updater: Updater;

    private static _init_tabs()
	{
		// If another tab logs in or out, update this tab.
		this._auth_broadcast.addEventListener('message', message => {
			if( message.data === 'logout' )
                location.pathname = '/';
		});
	}

    private static _logout_other_tabs()
	{
		this._auth_broadcast.postMessage('logout');
	}

    private static async _logout_unauthenticated_user(response: Response)
    {
        if( response.status === 401 && this.current_user ){
            this._remove_session();
            location.href = '/';
        }
    }

    private static _process_login_data(response_data: any)
    {
        this.current_user = Users.parse(response_data.data.user);
        if( this.current_user && this.current_user.lang )
            Locale.set_lang(this.current_user.lang);

        const token = response_data.data[CSRF_TOKEN_KEY];
        if( token )
            localStorage.setItem(CSRF_TOKEN_KEY, token);
    }

    private static _remove_session()
    {
        this._logout_other_tabs();
        localStorage.removeItem(CSRF_TOKEN_KEY);
        Push_Notifications.unregister();
    }
}
