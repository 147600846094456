import { Activity, Activities } from "../activities";
import { Auth } from "../auth";
import { Chat_Manager } from "../chats";
import { print_pretty_date, to_title_case } from "../core";
import { _t } from "../localization";
import { Scene, Scene_Manager } from "../scenes";
import { Activity_Overview } from "../ui/activity_overview";
import { print_global_message, print_global_messages } from "../ui/global_message";
import { Updater } from "../updater";
import { User_Summary_Data, Users } from "../users";
import { Activity_Discovery_Tips_Popup } from "../ui/popups/activity_discovery_tips_popup";
import { esc_attr, esc_html } from "../ui/DOM";
import { Push_Notification, Push_Notifications } from "../push_notifications";
import { Activity_Options_Popup } from "../ui/popups/activity_options_popup";

export class Activities_Discover_Scene extends Scene
{
    activity?: Activity;
    token?: string = '';

    constructor(updater: Updater)
    {
        super(updater, 'activities/discover');
        
        this.user_state = 'active';
        this._activity_overview = new Activity_Overview(updater);
        
        this._init_nothing_found_element();
        this._init_tools_element();

        Push_Notifications.register_callback((notifications: Push_Notification[]) => {
            this._remove_push_notifications(notifications);
        });
    }

    async open(args?: {[key: string]: string})
    {
        await super.open(args);
        await this._load_activity();

        new Activity_Discovery_Tips_Popup().open();

        this._remove_push_notifications();
    }

    async update()
    {
        this._print();

        this._update_save_button();

        if( this.activity )
            this.tools_element!.style.visibility = 'visible';
        else
            this.tools_element!.style.visibility = 'hidden';
    }

    _activity_overview: Activity_Overview;
    _nothing_found_element!: HTMLDivElement;
    _save_button!: HTMLElement;

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div')!;
        this._nothing_found_element.className = "center-abs text-center full-width";
        this._nothing_found_element.style.padding = '20px';
        this._nothing_found_element.innerHTML = `
            <img src="/assets/images/early-bird.png">
            <p>${_t('general/early_bird/explanation')}</p>`;

        const create_activity_button = document.createElement('button');
        create_activity_button.className = 'primary full-width';
        create_activity_button.style.marginBottom = '20px';
        create_activity_button.textContent = _t('activities/no_upcomming/create');
        create_activity_button.addEventListener('click', async () => {
            await Scene_Manager.open('activities/create');
            this._updater.run();
        });
        this._nothing_found_element.append(create_activity_button);

        const preference_button = document.createElement('button');
        preference_button.className = "full-width";
        preference_button.textContent = _t('users/edit_preferences');
        preference_button.style.marginBottom = '20px';
        preference_button.addEventListener('click', () => {
            Scene_Manager.open('profile/preferences');
            this._updater.run();
        });
        this._nothing_found_element.append(preference_button);
    }

    private _init_tools_element()
    {
        this.tools_element = document.createElement('div');
        this.tools_element.className = "bottom-menu flex-equal page-center-content";
        this.tools_element.innerHTML = `
            <a id="discover-decline-button" class="button">
                <img src="/assets/images/decline.png">
            </a>
            <a id="discover-save-button" class="button" style="padding-left: 20px; padding-right: 20px; flex-grow: 0 !important">
                <img src="/assets/images/save.png">
            </a>
            <a id="discover-join-button" class="button">
                <img src="/assets/images/accept.png"
            </a>`;

        this.tools_element.querySelector('#discover-join-button')!.addEventListener('click', this._on_join_click.bind(this));
        this.tools_element.querySelector('#discover-decline-button')!.addEventListener('click', this._on_decline_click.bind(this));

        this._save_button = this.tools_element.querySelector('#discover-save-button') as HTMLElement;
        this._save_button.addEventListener('click', this._on_save_click.bind(this));
    }

    private async _load_activity()
    {
        const log = {};

        const result = await Activities.discover(log);

        this.activity = result?.activity;
        this.token = result?.token;

        print_global_messages(log);
    }

    private async _on_join_click()
    {
        if( !this.activity )
            return;

        if( !confirm(_t('activities/join_confirm')) )
            return;

        const log = {};

        this.activity = await Activities.join(this.activity, this.token, log);
        print_global_messages(log);
        if( !this.activity ){
            this._updater.run();
            return;
        }

        this.token = '';

        setTimeout(async () => {
            if( await Chat_Manager.get(this.activity!.chat) ){
                await Scene_Manager.open('chat', {'uid': this.activity!.chat});
            }else{
                print_global_message('error', _t('activities/chat_not_opened'));
                await Scene_Manager.open('chats');
            }
            
            this._updater.run();
        }, 1000);
    }

    private async _on_decline_click()
    {
        if( !this.activity )
            return;

        const log = {};

        const result = await Activities.decline_and_discover(this.activity, this.token, log);
        this.activity = result?.activity;
        this.token = result?.token;

        if( result ){
            this._print();
            this._scroll_to_top();
        }
        
        print_global_messages(log);

        this._updater.run();
    }

    private async _on_options_click()
    {
        if( !this.activity )
            return;

        new Activity_Options_Popup(this.activity).open();
    }

    private async _on_save_click()
    {
        if( !this.activity )
            return;

        if( Auth.current_user?.account_type !== 'premium' ){
            alert(_t('general/premium_only')); // TO DO.
            return;
        }

        const log = {};

        const result = await Activities.save_and_discover(this.activity, this.token, log);
        this.activity = result?.activity;
        this.token = result?.token;

        if( result ){
            this._print();
            this._scroll_to_top();
        }

        print_global_messages(log);

        this._updater.run();
    }

    private _print()
    {
        if( !this.activity ){
            this.element.innerHTML = '';
            this.element.append(this._nothing_found_element);
            return;
        }

        let datetime = this.activity.datetime ? print_pretty_date(this.activity.datetime) : '';
        if( !datetime )
            datetime = '';

        const page_body_element = document.getElementById('page-body')!;
        const image_code = this.activity.image ? "background-image: url('" + esc_attr(Activities.get_image_url(this.activity.image)) + "'); " : '';
        const user_code = this._print_header_users_section(this.activity);

        this.element.innerHTML = `
        <div class="activity-summary" style="${image_code}background-position: center; background-size: cover">
            <div class="activity-header">
                <div class="users">${user_code}</div>
                <div class="name text-ellipsis">${esc_html(this.activity.name)}</div>
                <div class="meta date">${esc_html(datetime)}</div>
                <div class="meta city">${esc_html(this.activity.city)}</div>
                <hr/>
                <button class="more-info full-width">${to_title_case(_t('general/more_info'))}</button>
            </div>
        </div>
        <div class="page-section"></div>`;

        const page_section = this.element.querySelector('.page-section')!;
        page_section.append(this._activity_overview.element);
        this._activity_overview.update(this.activity);

        const summary_element = this.element.querySelector('.activity-summary') as HTMLElement;
        const overview_element = this.element.querySelector('.activity-overview') as HTMLElement;
        const more_info_button = this.element.querySelector('.more-info')!;
        more_info_button.addEventListener('click', () => overview_element.scrollIntoView({behavior: 'smooth'}) );

        const options_button = document.createElement('button');
        options_button.className = 'full-width';
        options_button.innerHTML = _t('general/options');
        options_button.style.marginTop = '5px';
        options_button.addEventListener('click', this._on_options_click.bind(this));
        page_section.append(options_button);

        setTimeout(() => {
            summary_element.style.height = page_body_element.clientHeight + 'px';;
        }, 10);
    }

    private _print_header_users_section(activity: Activity)
    {
        const users = new Array<User_Summary_Data>;
        if( activity.user )
            users.push(activity.user);
        for(const guest_data of activity.guests)
            users.push(guest_data);

        let code = '';
        const max_user_count = 3;
        const user_images_count = Math.min(max_user_count, users.length);
        for(let i = 0; i < user_images_count; i++){
            const class_name = 'framed-image ' + (users[i].relation === 'friend' ? 'friend' : '');
            const position = i === 0 ? 'relative' : 'absolute';
            code += `<img
                class="${class_name}"
                src="${esc_attr(Users.get_image_url(users[i].image, 'info_item'))}"
                style="position: ${esc_attr(position)}; margin-right: ${(user_images_count - i - 1) * 40}px; z-index: ${user_images_count - i}"
            />`;
        }

        if( users.length > max_user_count )
            code += `<div class="more text-center">${_t('activities/friends_more', users.length - max_user_count)}</div>`;

        return code;
    }

    private async _remove_push_notifications(notifications?: Push_Notification[])
    {
        if( !notifications )
            notifications = await Push_Notifications.get_all();

        Push_Notifications.remove(notifications.filter(
            notification => notification.tag === 'discover'
        ));
    }

    private _scroll_to_top()
    {
        document.getElementById('page-body')!.scrollTo(0, 0);
    }

    private _update_save_button()
    {
        if( Auth.current_user?.account_type !== 'premium' )
            // this._save_button.style.filter = 'grayscale(1)';
            this._save_button.style.display = 'none';
    }
}
