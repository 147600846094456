import { Scene } from "../scenes";
import { Updater } from "../updater";
import { _t } from "../localization";
import { Users } from "../users";
import { Auth } from "../auth";

export class Profile_Remove_Account_Scene extends Scene
{
    constructor(updater: Updater)
    {
        super(updater, 'profile/remove_account');

        this.user_state = 'logged_in';
        
        this.element.className = 'page-section';
        this.element.innerHTML = `<h1>${_t('users/remove_account')}</h1>${_t('users/remove_account/description')}`;

        const remove_button = document.createElement('button');
        remove_button.className = 'full-width danger';
        remove_button.innerHTML = _t('users/remove_account');
        remove_button.addEventListener('click', async () => {
            if( !Auth.current_user )
                return;

            if( !await Users.soft_remove(Auth.current_user) )
                return;
            
            alert(_t('users/remove_account/confirmation'));

            Auth.logout();
        });
        this.element.append(remove_button);
    }
}
