import { Auth } from "../../auth";
import { Message, Chat_Manager } from "../../chats";
import { _t } from "../../localization";
import { Popup } from "./popup";
import { Report_Popup } from "./report_popup";

export class Message_Options_Popup extends Popup
{
    constructor(message: Message, reply_cb: (message: Message) => void)
    {
        super('message_options', { title: 'Options' });

        this._message = message;
        this._reply_cb = reply_cb;

        const reply_button = document.createElement('button');
        reply_button.className = 'menu-item';
        reply_button.innerHTML = _t('chats/reply');
        reply_button.addEventListener('click', this._on_reply.bind(this));
        this._body.append(reply_button);

        const report_button = document.createElement('button');
        report_button.className = 'menu-item danger';
        report_button.innerHTML = _t('general/report');
        report_button.addEventListener('click', this._on_report.bind(this));
        this._body.append(report_button);

        if( message.user && Auth.current_user && message.user === Auth.current_user.uid && message.status === 'active' ){
            const delete_button = document.createElement('button');
            delete_button.className = 'menu-item danger';
            delete_button.innerHTML = _t('general/remove');
            delete_button.addEventListener('click', this._on_delete.bind(this));
            this._body.append(delete_button);
        }
    }

    private _message: Message;
    private _reply_cb: (message: Message) => void;

    private async _on_delete()
    {
        if( !confirm(_t('chats/remove_message_confirm')) )
            return;

        Chat_Manager.remove_message(this._message);
        this.close();
    }

    private async _on_reply()
    {
        this._reply_cb(this._message);
        this.close();
    }

    private async _on_report()
    {
        new Report_Popup(this._message).open();
        this.close();
    }
}