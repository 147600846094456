const globals = {

    APP_URL: 'https://app.wuddys.com',

    SERVER_URL: 'https://api.wuddys.com', // Main server.
    // SERVER_URL: 'https://dev.api.wuddys.com', // Dev server.
    // SERVER_URL: 'https://local.api.wuddys.com:3000', // Local server.

    API_VERSION: 'v1',
    AUTH_PATH: 'auth',
    HOME_PATH: 'activities/discover',
    IS_DEV: false,
    FORCE_TIPS: false,
    
    ANDROID_STORE_URL: 'https://play.google.com/store/apps/details?id=com.wuddys.app',
    IOS_STORE_URL: 'https://play.google.com/store/apps/details?id=com.wuddys.app',

    SEC_BEFORE_DISCONNECT_MESSAGE: 3000,
};

export default globals;
