import { _t } from "../localization";
import { Scene, Scene_Manager } from "../scenes";
import { Updater } from "../updater";

export class Profile_Settings_Scene extends Scene
{
    constructor(updater: Updater)
    {
        super(updater, 'profile/settings');

        this.user_state = 'logged_in';

        this.element.className = 'page-section';
        this.element.innerHTML = `
            <h1>${_t('general/settings')}</h1>
            <button class="menu-item blocked_users">${_t('users/blocked_users')}</button>
            <button class="menu-item remove_account">${_t('users/remove_account')}</button>`;

        this.element.querySelector('button.blocked_users')!.addEventListener('click', async () => {
            await Scene_Manager.open('users/blocked');
            this._updater.run();
        })

        this.element.querySelector('button.remove_account')!.addEventListener('click', async () => {
            await Scene_Manager.open('profile/remove_account');
            this._updater.run();
        })
    }
}
