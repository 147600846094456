import * as io from "socket.io-client";

import { API, API_Data, parse_API_messages } from "./api";
import * as core from "./core";
import { Network } from "./network";
import { print_global_message } from "./ui/global_message";
import { _t } from "./localization";

const MAX_NOTIFICATIONS_PER_REQUEST = 20;

export class Notification
{
    id = 0;
    name = '';
    content = '';
    image = '';
    url = '';
    status = '';
    created_at?: Date;
}

class Notification_Cache
{
    is_first_loaded = false;
    notifications = new Array<Notification>;
}

export class Notifications
{
    static get_image_url(image?: string, context='')
    {
        if( image )
            return image + '?context=' + encodeURIComponent(context);
        else
            return '/assets/images/default-notification-image.png';
    }

    static async mark_as_seen(log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT('/notifications/mark_as_seen');
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);

        return response.status === 200;
    }

    static parse(data: any, notification: Notification | false = false): Notification | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !notification )
            notification = new Notification;

        notification.id = data.id || '';
        notification.name = data.name || '';
        notification.content = data.content || '';
        notification.image = data.image || '';
        notification.url = data.url || '';
        notification.status = data.status || '';
        notification.created_at = core.UTC(data.created_at);

        return notification;
    }

    static async update(notification: Notification, data: API_Data, log: core.Log_Messages = {}): Promise<Notification | undefined>
    {
        const response = await API.PUT('/notifications/' + encodeURIComponent(notification.id), data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        return this.parse(result.data.notification);
    }

    static async update_before(notification: Notification, data: API_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT('/notifications/before/' + encodeURIComponent(notification.id), data);
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);
        return response.status === 200;
    }
}

export class Notification_Manager
{
    static get(args: Record<string, any>, check_cache: 'use_cache' | 'ignore_cache' = 'use_cache'): boolean
    {
        if( check_cache === 'use_cache' ){
            const cache = this._get_cache(args);
            if( cache ){
                // Use timeout for consistency if there was no cache.
                // Inconsistency might up the loading of the UI.
                setTimeout(() => {
                    dispatchEvent(
                        new CustomEvent('receive_notifications', {
                            detail: {
                                notifications: cache,
                                is_first_loaded: this._cache.is_first_loaded,
                            }
                        })
                    );
                }, 100);
                return true;
            }
        }
        
        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }
        
        this._socket.emit('notifications/get', args);

        return true;
    }

    // Check for new data since last request.
    static get_recent(): boolean
    {
        // If nothing has been loaded at all, we don't need to check as they will be loaded later anyway.
        if( this._cache.notifications.length === 0 && !this._cache.is_first_loaded )
            return true;

        return this.get({
            'after': this._cache.notifications[this._cache.notifications.length - 1]?.id,
            'pos': 'top',
            'context': 'recent',
        }, 'ignore_cache');
    }

    static has_unseen(): boolean
    {
        for(const notification of this._cache.notifications){
            if( notification.status === 'new' )
                return true;
        }

        return false;
    }

    static async init(socket: io.Socket): Promise<void>
    {
        this._socket = socket;

        addEventListener('network_connection', async () => {
            if( Network.is_connected() )
                this.get_recent();
        });

        socket.on('notifications/receive', this._on_receive.bind(this));

        this.get({
            'pos': 'bottom',
            'context': 'old',
        });
    }

    static async mark_as_read(notification: Notification, log: core.Log_Messages = {}): Promise<boolean>
    {
        if( !await Notifications.update(notification, {status: 'read'}) )
            return false;

        const notification_cache = this._cache.notifications.find(n => n.id === notification.id);
        if( notification_cache )
            notification_cache.status = 'read';

        return true;
    }

    static async mark_as_seen(log: core.Log_Messages = {}): Promise<boolean>
    {
        if( !await Notifications.mark_as_seen(log) )
            return false;

        this._cache.notifications.forEach(notification => {
            if( notification.status === 'new' )
                notification.status = 'seen';
        });

        return true;
    }

    private static _cache = new Notification_Cache;
    private static _socket?: io.Socket;

    private static _add_to_cache(notification: Notification)
    {
        this._cache.notifications.push(notification);
    }
    
    // Undefined if not cached yet. Empty array if requested from server, but nothing was found.
    private static _get_cache(args: Record<string, any>): Notification[] | undefined
    {
        let notifications = new Array<Notification>;

        if( args['after'] || args['before'] ){
            for(let i = 0; i < this._cache.notifications.length; i++){

                const notification = this._cache.notifications[i];

                if( args['after'] && notification.id <= args['after'] )
                    continue;

                if( args['before'] && notification.id >= args['before'] )
                    continue;
                
                notifications.push(notification);
                
                // If only one limit is set, we can break early.
                if( (typeof args['after'] !== typeof args['before']) && notifications.length >= MAX_NOTIFICATIONS_PER_REQUEST )
                    break;
            }
        }else{
            notifications = this._cache.notifications.slice(-MAX_NOTIFICATIONS_PER_REQUEST, this._cache.notifications.length);
        }

        if( this._cache.is_first_loaded || notifications.length > 0 )
            return notifications;
        
        return;
    }

    private static _on_receive(data: any)
    {
        if( typeof data !== 'object' )
            return;

        const all_notification_data = data['notifications'];
        if( !Array.isArray(all_notification_data) )
            return;
        
        const new_notifications = new Array<Notification>;
        for(const notification_data of all_notification_data){

            const notification = Notifications.parse(notification_data);
            if( !notification )
                continue;

            this._add_to_cache(notification)
            new_notifications.push(notification);
        }

        this._sort_notifications();

        if( data['context'] === 'recent' && new_notifications.length > 0 )
            this.get_recent();

        if( data['context'] === 'old' && all_notification_data.length === 0 )
            this._cache.is_first_loaded = true;

        dispatchEvent(
            new CustomEvent('receive_notifications', {
                detail: {
                    'notifications': new_notifications,
                    'is_first_loaded': this._cache.is_first_loaded,
                }
            })
        );
    }

    private static _sort_notifications(): Notification[]
    {
        return this._cache.notifications.sort((a, b) => a.id - b.id);
    }
}
