import { Auth } from "../../auth";
import { _t } from "../../localization";
import { User } from "../../users";
import { Popup } from "./popup";
import { Report_Popup } from "./report_popup";

export class User_Info_Popup extends Popup
{
    block_cb?: () => void;
    unfriend_cb?: () => void;

    constructor(user: User)
    {
        super('user_info', { title: 'Options' });

        this._user = user;

        if( !Auth.current_user )
            return;

        const report_button = document.createElement('button');
        report_button.className = 'menu-item danger';
        report_button.innerHTML = _t('general/report');
        report_button.addEventListener('click', this._on_report.bind(this));
        this._body.append(report_button);

        if( this._user.relation === 'friend' ){
            const unfriend_button = document.createElement('button');
            unfriend_button.className = 'menu-item danger';
            unfriend_button.innerHTML = _t('users/unfriend');
            unfriend_button.addEventListener('click', () => {
                this.close();
                if( this.unfriend_cb )
                    this.unfriend_cb();
            });
            this._body.append(unfriend_button);
        }

        if( this._user.relation !== 'blocked' ){
            const unblock_button = document.createElement('button');
            unblock_button.className = 'menu-item danger';
            unblock_button.innerHTML = _t('users/block');
            unblock_button.addEventListener('click', () => {
                this.close();
                if( this.block_cb )
                    this.block_cb();
            });
            this._body.append(unblock_button);
        }
    }

    private _user: User;

    private async _on_report()
    {
        new Report_Popup(this._user).open();
        this.close();
    }
}
