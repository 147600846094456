import { Auth }                 from "../auth"
import { _t } from "../localization"
import { Scene, Scene_Manager } from "../scenes"
import { print_global_messages } from "../ui/global_message"
import { Updater }              from "../updater"
import { Users }                from "../users"

export class Profile_Scene extends Scene
{
    name_element: HTMLSpanElement
    profile_picture_element: HTMLImageElement

    constructor(updater: Updater)
    {
        super(updater, 'profile')

        this.user_state = 'logged_in'

        this.element.className = 'page-section'
        this.element.innerHTML = `
            <h1>${_t('users/profile')}</h1>
            <div class="personal-info-header" style="display: flex; margin-bottom: 20px">
                <img class="profile-picture clickable" src=""/>
                <span class="name text-ellipsis"></span>
            </div>
            <button class="menu-item personal-info">${_t('users/personal_info')}</button>
            <button class="menu-item preferences">${_t('users/preferences')}</button>
            <button class="menu-item settings">${_t('general/settings')}</button>
            <button class="menu-item logout">${_t('auth/log_out')}</button>`

        this.name_element = this.element.querySelector('.personal-info-header .name')!
        this.profile_picture_element = this.element.querySelector('.profile-picture')!

        this.element.querySelector('img.profile-picture')!.addEventListener('click', async () => {
            await Scene_Manager.open('profile/picture')
            this._updater.run()
        })

        this.element.querySelector('button.personal-info')!.addEventListener('click', async () => {
            await Scene_Manager.open('profile/personal_info')
            this._updater.run()
        })

        this.element.querySelector('button.preferences')!.addEventListener('click', async () => {
            await Scene_Manager.open('profile/preferences')
            this._updater.run()
        })

        this.element.querySelector('button.settings')!.addEventListener('click', async () => {
            await Scene_Manager.open('profile/settings')
            this._updater.run()
        })


        this.element.querySelector('button.logout')!.addEventListener('click', () => {
            const log = {};
            Auth.logout(log);
            print_global_messages(log);
        })
    }

    async open()
    {
        await super.open()
        
        const user = Auth.current_user!
        this.name_element.textContent = user.first_name + ' ' + user.last_name
        this.profile_picture_element.src = Users.get_image_url(Auth.current_user?.image, 'info_item')
    }
}
